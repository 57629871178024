































































import { Component, Prop } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import GUUID from '@/utils/GUUID';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import Article from '@/models/graphql/Article';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import EntityType from '@/utils/enums/EntityType';

@Component({
  components: {
    FontAwesomeComponent,
  },
})
export default class FaqListWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: '' })
  private readonly title!: string;

  @Prop({ required: false, default: '' })
  private readonly subtitle!: string;

  @Prop({ required: false, default: null })
  private readonly category!: number;

  @Prop({ required: false, default: () => [] })
  private readonly data!: Article[];

  private listId = GUUID.uuidv4();

  created(): void {
    if (this.payload
      && 'category' in this.payload
      && this.payload.category) {
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'article',
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.ARTICLE_FILTER,
            value: {
              categories: {
                id: this.payload.category,
                schemaCode: '%community%',
              },
            },
          },
          orderBy: {
            type: GqlEntityOrderingType.ARTICLE_ORDERING,
            value: ['displaySequence_asc'],
          },
        }),
        fragmentName: 'articleBaseFragment',
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing category in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }

  private logStats(article: string): void {
    this.$logger.logMatomoStats(
      this.authUser,
      this.community.code || '',
      EntityType.ARTICLE,
      StatLoggerActions.CLICK,
      'faqHelp',
      -1,
      article,
      StatLoggerCategories.HELP,
      this.$i18n.locale,
    );
  }
}
